import React from 'react';
import PropTypes from 'prop-types';

import { ItemDesktop } from '@mshops-components-library/item';
import { useRenderContext } from '../../pages/home/context';
import useCheckoutRedirect from '../../hooks/useCheckoutRedirect';

const Item = (props) => {
  const { item, i18n } = props;
  const { features } = useRenderContext();
  const productCartInfo = useCheckoutRedirect(item?.product_cart_info);
  const shippingTitle = i18n.gettext('Envío gratis');
  const ftNewComponentsV2 = features?.includes('ft-new-components-v2');

  return (<ItemDesktop
    {...props}
    {...item}
    shippingInfo={item?.shipping}
    product_cart_info={productCartInfo}
    shippingTitle={shippingTitle}
    ftNewComponentsV2={ftNewComponentsV2}
  />);
};

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    price: PropTypes.shape({
      currency_id: PropTypes.string.isRequired,
    }),
    shipping: PropTypes.shape({
      fulfillment: PropTypes.bool.isRequired,
    }),
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

export default Item;
